<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getHolidaysBySupplierId')" class="full-height-layout fill mx-4 mb-3">
        <div class="row">
            <div class="col-6">
                <h2 class="font-weight-normal">Holidays</h2>
                <p>You can assign holidays to locations via the <router-link :to="locationsOverviewRoute">Locations overview</router-link></p>
            </div>

            <div class="col-6 text-right mt-20">
                <mercur-button
                        v-if="isAllowedTo('SupplierCentral/createHoliday')"
                        :to="addSupplierHolidayRoute"
                        class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    <span>Add new holiday</span>
                </mercur-button>
            </div>
        </div>

        <ag-grid-vue
            class="ag-grid fill ag-theme-material border"
            :columnDefs="columnDefs"
            :animateRows="true"
            rowModelType="clientSide"
            :rowData="holidays"
            :pagination="true"
            :enableRangeSelection="false"
            :suppressCellSelection="true"
            @grid-ready="onGridReady"
            :detailRowHeight="400"
        ></ag-grid-vue>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'

export default {
    name: 'HolidaysOverview',
    components: { AgGridVue },
    data () {
        return {
            gridApi: null,
            columnDefs: null,
        }
    },
    computed: {
        locationsOverviewRoute () {
            return {
                name: 'SupplierLocations',
                params: {
                    ...this.$route.params,
                },
            }
        },
        holidays () {
            const holidays = this.$store.state.holidays.holidays[this.supplierId]

            if (!holidays) {
                return null
            }

            return holidays
        },
        addSupplierHolidayRoute () {
            return {
                name: 'SupplierAddHoliday',
                params: {
                    ...this.$route.params,
                },
            }
        },
    },
    beforeMount () {
        this.columnDefs = [
            {
                headerName: 'Name',
                field: 'holidayName',
                sortable: true,
            },
            {
                headerName: 'Start',
                field: 'holidayFrom',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'End',
                field: 'holidayTo',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'Yearly',
                field: 'yearly',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return value ? 'yes' : 'no'
                },
            },
            {
                headerName: '',
                width: 100,
                cellRendererFramework: 'ActionsCell',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                text: 'Edit',
                                icon: 'fas fa-edit',
                                hide: () => {
                                    return !this.isAllowedTo('SupplierCentral/updateHoliday')
                                },
                                to: (params) => {
                                    return {
                                        name: 'SupplierEditHoliday',
                                        params: {
                                            ...params.data,
                                        },
                                    }
                                },
                            },
                        ],
                    }
                },
            },
        ]
    },
    methods: {
        onGridReady (params) {
            this.gridApi = params.api
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })
        },
    },
    created () {
        if (this.isAllowedTo('SupplierCentral/getHolidaysBySupplierId')) {
            this.$store.dispatch('holidays/fetchSupplierHolidays', this.supplierId)
        }
    },
}
</script>
